<script setup lang="ts">
import { useMediaQuery } from "@vueuse/core";
import { KippieAccordion, SvgIcon, FooterItem, KippieLink, NavLink } from "../";

defineProps<{
	footerItems: FooterItem[];
	policies: NavLink[];
	copyright: string;
	rightsReserved: string;
}>();

const isMobile = useMediaQuery("(max-width: 1023px)");
</script>

<template>
	<footer class="bg-black-light text-white py-10 relative">
		<div class="container grid lg:grid-cols-3 gap-x-8">
			<KippieAccordion
				v-if="isMobile"
				id="links"
				mulitple
				border-top
				:sections="['service', 'account', 'kippie']"
				:open="['service', 'account', 'kippie']"
			>
				<template #title-service>
					<h3 class="text-2xl font-bold">
						{{ footerItems[0].title }}
					</h3>
				</template>
				<template #content-service>
					<ul class="flex flex-col gap-y-2 pb-8">
						<li v-for="link in footerItems[0].links" :key="link.label">
							<KippieLink
								:aria-label="link.label"
								:to="link.url"
								:underline="false"
								class="text-base leading-7"
							>
								{{ link.label }}
							</KippieLink>
						</li>
					</ul>
				</template>

				<template #title-account>
					<h3 class="text-2xl font-bold">
						{{ footerItems[1].title }}
					</h3>
				</template>
				<template #content-account>
					<ul class="flex flex-col gap-y-2 pb-8">
						<li v-for="link in footerItems[1].links" :key="link.label">
							<KippieLink
								:aria-label="link.label"
								:to="link.url"
								:underline="false"
								class="text-base leading-7"
							>
								{{ link.label }}
							</KippieLink>
						</li>
					</ul>
				</template>

				<template #title-kippie>
					<h3 class="text-2xl font-bold">
						{{ footerItems[2].title }}
					</h3>
				</template>
				<template #content-kippie>
					<ul class="flex flex-col gap-y-2 pb-8">
						<li v-for="link in footerItems[2].links" :key="link.label">
							<KippieLink
								:aria-label="link.label"
								:to="link.url"
								:underline="false"
								class="text-base leading-7"
							>
								{{ link.label }}
							</KippieLink>
						</li>
					</ul>
				</template>
			</KippieAccordion>
			<template v-else>
				<div v-for="item in footerItems.slice(0, 3)" :key="item.title">
					<h3 class="text-2xl font-bold py-4">
						{{ item.title }}
					</h3>
					<ul class="flex flex-col gap-y-2">
						<KippieLink
							v-for="link in item.links"
							:key="link.label"
							:to="link.url"
							:underline="false"
							class="text-base leading-7"
						>
							{{ link.label }}
						</KippieLink>
					</ul>
				</div>
			</template>
		</div>
		<div class="container flex flex-wrap gap-x-8 gap-y-4 py-8">
			<slot name="socials" />
		</div>
		<div class="container text-xs leading-tight flex flex-wrap gap-y-2">
			<KippieLink
				v-for="policy in policies"
				:key="policy.label"
				:to="policy.url"
				:underline="false"
				:aria-label="policy.label"
				class="px-2 first:pl-0 border-r border-white"
			>
				{{ policy.label }}
			</KippieLink>
			<p class="px-2 border-r border-white">
				{{ copyright }}
			</p>
			<p class="px-2">
				{{ rightsReserved }}
			</p>
		</div>
		<SvgIcon name="chicken" class="text-[#555757] w-9 lg:w-16 absolute bottom-0 right-3" />
	</footer>
</template>
